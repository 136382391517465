<template>
    <div>
        <label>Scan barcode for indices</label>
        <input class="form-control form-control-sm" type="text" v-model="barcode" />
        <div v-if="barcode.length" class="my-3">
            <table class="table table-smw">
                <tbody>
                    <tr>
                        <td v-for="(char, index) in barcode.split().join()" :key="index">
                            <b>{{ char }}</b>
                        </td>
                    </tr>
                    <tr>
                        <td v-for="(char, index) in barcode.split().join()" :key="index">{{ index }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr>
    </div>
</template>

<script>
export default {
    name: 'BarcodeIndexInput',
    data() {
        return {
            barcode: ''
        };
    }
};
</script>

<style scoped>
.table {
    font-size: 0.8rem;
    text-align: center;
    display: block;
    overflow: scroll;
}

.table td {
    padding-top: 2px;
    padding-bottom: 2px;
    width: 5px;
    cursor: pointer;
}
</style>

