<template>
    <div>
        <div class="modal fade" id="gs1-scan-modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <input type="text" ref="gs1Input" v-model="barcode" @blur="hideGS1ScanModal" @change="parseGS1Barcode"
                               class="ui-hidden-accessible">
                        <div class="prompt-frame d-flex align-items-center justify-content-center">
                            <Spinner message="Waiting for scan" size="60" line-fg-color="gray"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="gs1-scan-error-modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body d-flex flex-column">
                        <strong>The following {{ hasMultipleOverwritingFields ? 'fields already exist' : 'field already exists' }}:</strong>
                        <span v-for="field in this.product.fields" v-if="field.is_overwriting" class="ml-4">
                            {{ field.type_name }}
                        </span>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-light" @click="errorModal('KEEP')">Keep</button>
                        <button type="button" class="btn btn-sm btn-secondary ml-2" @click="errorModal('OVERWRITE')">Overwrite</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Spinner from "vue-simple-spinner";

    export default ({
        name: 'GS1ScanModal',
        components: {
            Spinner
        },
        props: {
            product: Object,
            fieldTypes: Array
        },
        data() {
            return {
                barcode: '',
                fieldMapping: {}
            }
        },
        mounted() {
            let vm = this;
            $('#gs1-scan-modal').on('shown.bs.modal', function () {
                vm.focusGS1Input();
            });
        },
        computed: {
            hasMultipleOverwritingFields() {
                if (this.product && this.product.fields) {
                    return this.product.fields.filter(field => field.is_overwriting).length > 1;
                }
            }
        },
        methods: {
            focusGS1Input() {
                this.barcode = '';
                this.$refs.gs1Input.focus();
            },
            parseGS1Barcode() {
                // In the event the user pastes the GS1 xml from a rich text editor, un-enrich the quotes
                this.barcode = this.barcode.replace(/[“”]/g, '"');

                let parser = new DOMParser();
                let text = parser.parseFromString(this.barcode, 'text/xml');
                let parentNode = text.documentElement.getAttribute('dfi');

                if (parentNode === 'GS1') {
                    let childNodes = text.documentElement.childNodes;

                    try {
                        let vue_this = this;
                        this.fieldTypes.forEach(fieldType => {
                            if (fieldType.tag) {
                                vue_this.fieldMapping[fieldType.tag] = fieldType.name;
                            }
                        });
                    } catch (error) {
                        console.log(error);
                    }

                    // Loop through all found GS1 fields
                    for (let i = 0; i < childNodes.length; i++) {
                        let key = childNodes[i].nodeName;
                        let fieldName = this.fieldMapping[key] ? this.fieldMapping[key] : key;

                        if (!this.product.fields.some(field => {
                            // If a product's field's name matches the scanned field
                            if(field.type_name === fieldName) {
                                let newField = { ...field };
                                delete newField.id;
                                newField.is_gs1 = true;
                                if (field.is_new) {
                                    const index = this.product.fields.indexOf(field);
                                    if (index > -1) {
                                        this.product.fields.splice(index, 1);
                                        newField.is_new = true;
                                    }
                                } else {
                                    field.getting_overwritten = true;
                                    newField.is_overwriting = true;
                                }
                                this.product.fields.push(newField);

                                return true;
                            }
                            return false;
                        })) {
                            // If the field doesn't already exist, push the new field directly to the product's fields
                            this.product.fields.push({
                                type_name: fieldName,
                                type_tag: key,
                                notes: '',
                                is_gs1: true,
                                is_new: true
                            });
                        }
                    }
                    this.hideGS1ScanModal();

                    // If there are any fields overwriting others, prompt user for resolution
                    if (this.product.fields.find(field => field.is_overwriting)) {
                        $('#gs1-scan-error-modal').modal({
                            backdrop: 'static',
                            keyboard: false,
                            show: true
                        });
                    }
                }

            },
            hideGS1ScanModal() {
                this.barcode = '';
                $('#gs1-scan-modal').modal('hide');
            },
            errorModal(resolution) {
                if (resolution === 'KEEP') {
                    // If the user wants to keep their current fields, clear the overwritten fields
                    this.product.fields = this.product.fields.filter(field => !field.is_overwriting);

                    this.product.fields.forEach(field => {
                        if (field.getting_overwritten) {
                            delete field.getting_overwritten;
                        }
                    });
                }
                $('#gs1-scan-error-modal').modal('hide');
            }
        }
    })
</script>

<style scoped>
    .prompt-frame {
        min-height: 500px
    }

    .ui-hidden-accessible {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
    }
</style>
