<template>
    <div class="modal fade" id="product-field-modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <b class="modal-title" id="product-field-modal-title">{{ modalTitle }}</b>
                </div>
                <div class="modal-body">
                    <div class="form-group custom-switch mb-2 mr-3">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            id="is-date"
                            v-model="field.is_date"
                        />
                        <label for="is-date" class="custom-control-label">Is date</label>
                    </div>
                    <div class="form-group custom-switch mb-0 mr-3" v-if="!field.is_gs1">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            id="has-barcode"
                            v-model="field.is_barcode"
                        />
                        <label for="has-barcode" class="custom-control-label">Has barcode</label>
                    </div>
                    <hr/>
                    <BarcodeIndexInput v-if="field.is_barcode"/>
                    <form @submit.prevent="submitForm" id="product-field-form">
                        <div class="form-group">
                            <label class="col-form-label">Name</label>
                            <select class="form-control form-control-sm" v-model="field.type_name" required>
                                <option v-for="fieldType in fieldTypes"
                                        :value="fieldType.name"
                                        :disabled="product.fields ? fieldType.name !== field.type_name && product.fields.some(temp_field => (temp_field.type_name === fieldType.name)) : false">
                                    {{ fieldType.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label class="col-form-label">Field description (optional)</label>
                            <textarea
                                class="form-control form-control-sm"
                                type="text"
                                maxlength="50"
                                v-model="field.notes"
                            />
                        </div>
                        <div class="form-group" v-if="field.is_date && !field.is_gs1">
                            <label class="col-form-label">Date format</label>
                            <select class="form-control form-control-sm" v-model="date_format" required>
                                <option v-for="dateFormat in dateFormats"
                                        :value="dateFormat.format">
                                    {{ dateFormat.format }}
                                </option>
                            </select>
                        </div>
                        <div class="row" v-if="field.is_barcode">
                            <div class="form-group col">
                                <label class="col-form-label">Start index</label>
                                <input
                                    class="form-control form-control-sm"
                                    type="number"
                                    min="0"
                                    v-model="field.barcode_start_index"
                                    required
                                />
                            </div>
                            <div class="form-group col">
                                <label class="col-form-label">End index</label>
                                <input
                                    class="form-control form-control-sm"
                                    type="number"
                                    min="0"
                                    v-model="field.barcode_end_index"
                                    required
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-light" data-dismiss="modal">Close</button>
                    <SubmitButton :form="'product-field-form'" :btnDisabled="submitting" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SubmitButton from '../shared/SubmitButton.vue';
import BarcodeIndexInput from '../../components/forms/BarcodeIndexInput.vue';
import {addDateFormat, updateField} from "../../services/FieldService";

export default {
    name: 'FieldModal',
    components: {
        SubmitButton,
        BarcodeIndexInput
    },
    props: {
        currentField: Object,
        formAction: String,
        fieldTypes: Array,
        dateFormats: Array,
        product: Object
    },
    data() {
        return {
            field: {
                type_name: '',
                notes: '',
                date: 0,
                is_barcode: false,
                is_date: false,
                is_gs1: false,
                barcode_start_index: '',
                barcode_end_index: ''
            },
            barcode: '',
            date_format: '',
            modalTitle: 'Add field',
            submitting: false
        };
    },
    computed: {
        firstAvailableFieldTypeName() {
            if (this.product) {
                if (this.product.fields.length > 0) {
                    let availableFieldTypes = [];
                    this.fieldTypes.forEach(fieldType => {
                        if (!this.product.fields.some(temp_field => (temp_field.type_name === fieldType.name))) {
                            availableFieldTypes.push(fieldType.name)
                        }
                    });
                    return availableFieldTypes[0];
                } else if (this.fieldTypes.length > 0) {
                    return this.fieldTypes[0].name;
                }
            }
        }
    },
    watch: {
        currentField() {
            this.setField();
        }
    },
    methods: {
        setField() {
            if (this.formAction === 'add') {
                Object.keys(this.field).forEach(key => {
                    this.field[key] = key === 'is_date' || key === 'is_barcode' ? false : '';
                });
                this.modalTitle = 'Add field';
                this.field.type_name = this.firstAvailableFieldTypeName;
            } else {
                this.field = { ...this.currentField };
                this.modalTitle = this.field.type_name;
            }
            if (this.field.is_date && this.field.date) {
                this.date_format = this.dateFormats.find(dateFormat => dateFormat.id === this.field.date).format;
            } else {
                this.date_format = this.dateFormats[0].format;
            }
            this.field.product = this.$route.params.id;
        },
        async submitForm() {
            try {
                this.submitting = true;
                Object.keys(this.field).forEach(key => {
                    this.field[key] = this.field[key] !== '' ? this.field[key] : null;
                });
                if (this.formAction === 'add') {
                    this.field.is_new = true;
                    this.field.type = this.fieldTypes.find(fieldType => fieldType.name === this.field.type_name).id;
                    if (this.field.is_date) {
                        this.field.date = this.dateFormats.find(dateFormat => dateFormat.format === this.date_format).id;
                    }
                } else {
                    if (this.field.is_date) {
                        if (this.field.is_gs1) {
                            this.field.date = this.dateFormats.find(dateFormat => dateFormat.format === 'YYMMDD').id;
                        } else {
                            this.field.date = this.dateFormats.find(dateFormat => dateFormat.format === this.date_format).id;
                        }
                    } else {
                        this.field.date = null;
                    }
                    if (!this.field.is_new) {
                        await updateField(this.field);
                    }
                }
                this.$emit('submit-form', this.field);
            } catch (error) {
                console.log(error);
            }
            $('#product-field-modal').modal('hide');
            // Creating a new, un-related field is important. Otherwise, new changes will be emitted.
            this.field = {...this.field};
            this.submitting = false;
        }
    }
};
</script>
