<template>
    <div class="container my-4">
        <div class="card">
            <div class="card-header d-flex align-items-center">
                <div>Fields for {{ product.name }}</div>
                <div class="dropdown ml-auto" v-if="hasPermissions('modify:products')">
                    <a role="button" class="ml-2 text-secondary" data-toggle="dropdown" style="cursor: pointer;">
                        <i class="fas fa-1x fa-ellipsis-h"/>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                        <a href="#gs1-scan-modal"
                           data-toggle="modal"
                           class="dropdown-item">Scan GS1</a>
                        <a href="#product-field-modal"
                           data-toggle="modal"
                           class="dropdown-item"
                           @click="setModalData({}, 'add')">Add</a>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div v-if="product.fields && !product.fields.length">No information to display</div>
                <table v-else class="table table-sm table-borderless mb-0" id="fields-table">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Notes</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="field in product.fields"
                        v-if="!field.getting_overwritten"
                        :key="field.id"
                        :class="{ 'new': field.is_new, 'overwriting': field.is_overwriting }">
                        <td class="d-flex align-items-center">
                            {{ field.type_name }}
                            <span class="ml-2 badge badge-pill badge-light" v-if="field.is_gs1">GS1</span>
                            <span class="ml-2 badge badge-pill badge-light" v-else-if="field.is_barcode">Barcode</span>
                        </td>
                        <td>{{ field.notes || 'No notes provided' }}</td>
                        <td v-if="hasPermissions('modify:products')">
                            <button type="button"
                                    data-toggle="dropdown"
                                    class="btn btn-link text-secondary p-1">
                                <i class="fas fa-1x fa-ellipsis-h"/>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a href="#product-field-modal"
                                   data-toggle="modal"
                                   class="dropdown-item"
                                   @click="setModalData(field, 'updateField')">Edit</a>
                                <a href="#delete-object-modal"
                                   data-toggle="modal"
                                   class="dropdown-item text-danger"
                                   @click="currentField = field">Delete</a>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer d-flex">
                <button class="btn btn-sm btn-light" @click="$router.go(-1)">Back</button>
                <button class="btn btn-sm btn-primary ml-auto" v-if="hasNewOrOverwritingFields" @click="saveFields">Save</button>
            </div>
        </div>
        <DeleteObjectModal
                @delete-object="deleteField"
                :currentObject="currentField"
                :type="'field'"
        />
        <FieldModal
                @submit-form="submitModalForm"
                @added-date-format="addedDateFormat"
                :currentField="currentField"
                :formAction="formAction"
                :fieldTypes="fieldTypes"
                :dateFormats="dateFormats"
                :product="product"
        />
        <GS1ScanModal
                :product="product"
                :currentField="currentField"
                ref="gs1ScanModal"
                :fieldTypes="fieldTypes"
                :overwrittenFieldIds="overwrittenFieldIds"
        />
    </div>
</template>

<script>
    import {getProduct} from '../../services/ProductService';
    import DeleteObjectModal from '../../components/modals/DeleteObjectModal.vue';
    import FieldModal from '../../components/modals/FieldModal.vue';
    import GS1ScanModal from '../../components/modals/GS1ScanModal.vue';
    import {addField, getDateFormats, getFieldTypes, updateField} from "../../services/FieldService";
    import { hasPermissions } from '../../helpers/permissionUtils'

    export default {
        name: 'ProductFields',
        components: {
            DeleteObjectModal,
            FieldModal,
            GS1ScanModal
        },
        data() {
            return {
                product: {},
                currentField: {},
                formAction: 'add',
                fieldTypes: [],
                dateFormats: [],
                overwrittenFieldIds: []
            };
        },
        async created() {
            try {
                this.refreshAll();
            } catch (error) {
                console.log(error);
                this.$router.go(-1);
            }
        },
        computed: {
            hasNewOrOverwritingFields() {
                if (this.product && this.product.fields) {
                    return this.product.fields.filter(field => field.is_new || field.is_overwriting).length;
                }
            }
        },
        methods: {
            refreshAll() {
                this.refreshProduct();
                this.refreshFieldTypes();
                this.refreshDateFormats();
            },
            async refreshProduct() {
                const productResponse = await getProduct(this.$route.params.id);
                this.product = productResponse.data;
            },
            async refreshFieldTypes() {
                let fieldTypeResponse = await getFieldTypes();
                this.fieldTypes = fieldTypeResponse.data;
            },
            async refreshDateFormats() {
                let dateFormatResponse = await getDateFormats();
                this.dateFormats = dateFormatResponse.data;
            },
            deleteField(type_name) {
                this.product.fields = this.product.fields.filter(field => field.type_name !== type_name);
            },
            submitModalForm(field) {
                if (this.formAction === 'add') {
                    this.product.fields.push(field);
                } else {
                    const index = this.product.fields.findIndex(f => f.id === field.id);
                    this.product.fields.splice(index, 1, field);
                }
            },
            setModalData(field, formAction) {
                this.currentField = field;
                this.formAction = formAction;
            },
            addedDateFormat(dateFormat) {
                this.dateFormats.push(dateFormat);
            },
            async saveFields() {
                try {
                    for (let field of this.product.fields) {
                        if (field.is_overwriting) {
                            this.product.fields.forEach(tempField => {
                                if (tempField.getting_overwritten && tempField.id && tempField.type_name === field.type_name) {
                                    let fieldID = tempField.id;
                                    delete tempField.id;
                                    field.id = fieldID;
                                }
                            });

                            if (field.is_date) {
                                field.date = this.dateFormats.find(date => date.format === 'YYMMDD').id
                            } else {
                                field.date = null;
                            }
                            field.is_barcode = false;
                            await updateField(field)
                        } else if (field.is_new) {
                            field.product = this.product.id;
                            field.type = this.fieldTypes.find(fieldType => fieldType.name === field.type_name).id;
                            field.is_gs1 = field.is_gs1 ? field.is_gs1 : false;
                            await addField(field);
                        }
                    }
                    this.refreshAll();
                } catch (error) {
                    console.log(error);
                }
            },
            hasPermissions,
        }
    };
</script>

<style scoped>
    .new {
        color: #28a745;
    }

    .overwriting {
        color: #03a9f4;
    }
</style>